import React from "react";
import { Collapse, Typography, Paper, IconButton, Box, DialogContent, Divider, Stack } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TranslationContent from "../../../../Translations/TranslationContent";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import OpenInNewOffIcon from '@mui/icons-material/OpenInNewOff';
import Draggable from 'react-draggable';
import { useViewport } from "../../../../Context/ViewportProvider";


const SettingContainer = ({ children, label, open, toggleOpen, dialogOpen, toggleDialog }) => {
  const [viewDialogContent, setViewDialogContent] = React.useState(true);
  const { height } = useViewport();

  return(
    <Grid xs={12} md={6} xl={4} pl={2} pr={2}>
      {dialogOpen &&
        <Draggable
        handle="#draggable-dialog-title" // Only make the title draggable
        cancel={'[class*="MuiDialogContent-root"]'} // Disable dragging for content area
        >
          <Box
            component={Paper}
            elevation={24}
            sx={{
              position: 'absolute',
              top: '20%',
              left: '20%',
              zIndex: 1300,
              padding: '16px',
              minWidth: '300px',
              maxHeight: height * 0.9,
              overflow: "auto"
            }}
          >
            <Stack direction="row" sx={{alignItems: "center", width: "100%", cursor: 'move'}} id="draggable-dialog-title">
              <Typography color="primary"><b><TranslationContent contentID={label} /></b></Typography>
              <IconButton color="error" sx={{mr: 0, ml: "auto"}} onClick={() => {toggleDialog()}}><OpenInNewOffIcon /></IconButton>
              <IconButton
                sx={{float: "right", verticalAlign: "middle", mt: -0.5}}
                color="primary"
                aria-label="expand settings"
                size="small"
                onClick={() => setViewDialogContent(prev => !prev)}
              >
                {viewDialogContent ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </Stack>
            <Divider />
            <Collapse in={viewDialogContent}>
              <DialogContent>
                {children}            
              </DialogContent>
            </Collapse>
          </Box>
        </Draggable>
      }
      <Paper orientation={10} elevation={12} sx={{p: 1}}>
        <Typography variant="caption" color="primary" sx={{pl: 1.5}}><b><TranslationContent contentID={label} /></b></Typography>
        <IconButton
          sx={{float: "right", verticalAlign: "middle", mt: -0.5}}
          color="primary"
          aria-label="expand settings"
          size="small"
          onClick={() => toggleOpen(prev => !prev)}
        >
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
        <IconButton
          disabled={dialogOpen}
          sx={{float: "right", verticalAlign: "middle", mt: -0.5}}
          color="primary"
          aria-label="open setting modal"
          size="small"
          onClick={() => toggleDialog(prev => !prev)}
        >
          <OpenInNewIcon />
        </IconButton>
        <br />
        <Collapse in={open} unmountOnExit sx={{width: "100%"}}>
        <div style={{width: "100%", height: "10px"}} />
          {children}
        </Collapse>
      </Paper>
    </Grid>
  )
}

export default SettingContainer;
import { createHeaders } from "./CreateData/createHeaders";
import { createCategories } from "./CreateData/createCategories";
import { createDefaultDiscounts } from "./CreateData/createDefaultDiscounts";
import { createDataValues } from "./CreateData/createDataValues";
import { createInitDiscounts } from "./CreateData/createInitDiscounts";
import { createFilters } from "./CreateData/createFilters";
import { logger } from "../services/logger";


 export const initializeData = async (data) => {

  let headers         = await createHeaders(data);
  let categories      = await createCategories(headers);
  let initDataValues  = await createDataValues(data, headers);
  let initDataValuesWithCalculatedDiscounts = await createInitDiscounts(initDataValues);
  let defaultDiscounts = await createDefaultDiscounts(initDataValuesWithCalculatedDiscounts);
  let filters         = await createFilters();

  logger(initDataValuesWithCalculatedDiscounts);

  let initData = {
    headers: [...headers],
    categories: [...categories],
    defaultDiscounts: [...defaultDiscounts],
    data: [...initDataValuesWithCalculatedDiscounts],
    filters: [...filters],
  }

  return initData;
}
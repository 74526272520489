import React from "react";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import SettingContainer from "../../Settings/SettingContainer";


const LineValueSettings = ({ numericFilters, valueButtons, updateLineValues }) => {
  const [showSettings, setShowSettings] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState(false);

  return(
    <SettingContainer
      open={showSettings}
      toggleOpen={() => setShowSettings(!showSettings)}
      label="values"
      dialogOpen={showDialog}
      toggleDialog={() => setShowDialog(!showDialog)}
    >
      <FormGroup sx={{ ml: 1.5 }}>
        {numericFilters.map((numericFilter, index) => 
          <FormControlLabel
            key={numericFilter.objectKey + index} 
            label={numericFilter.objectKey.split(":")[0]}
            control={
              <Checkbox 
                checked={valueButtons.some(button => button.objectKey === numericFilter.objectKey)} 
                onChange={() => updateLineValues(numericFilter.objectKey)}
                size="small"
              />
            }
          />
        )}
      </FormGroup>
    </SettingContainer>
  )
}

export default LineValueSettings;
import React from "react";
import { useViewport } from "../../../../../Context/ViewportProvider";
import SettingContainer from "../../Settings/SettingContainer";
import { Collapse, Stack} from "@mui/material";
import SwitchSetting from "../../Settings/SwitchSetting";
import SliderSetting from "../../Settings/SliderSetting";


const PieInteractivitySettings = 
  ({
    isInteractive,
    updateIsInteractive,
    customTooltip,
    updateCustomTooltip,
    activeInnerRadiusOffset,
    updateActiveInnerRadiusOffset,
    activeOuterRadiusOffset,
    updateActiveOuterRadiusOffset,
  }) => {
  const [showSettings, setShowSettings] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState(false);
  const { width } = useViewport();

  return(
    <SettingContainer
      open={showSettings}
      toggleOpen={() => setShowSettings(!showSettings)}
      label="interactivity"
      dialogOpen={showDialog}
      toggleDialog={() => setShowDialog(!showDialog)}
    >
      <Stack direction={width > 500 ? "row" : "column"} spacing={2} sx={{alignItems: "flex-start", pb: 2, ml: 0.5}}>
        <SwitchSetting
          label="active"
          value={isInteractive}
          handleChange={updateIsInteractive}  
        />
        <SwitchSetting
          label="tooltipWithAllInfo"
          value={customTooltip}
          handleChange={updateCustomTooltip}
          disabled={!isInteractive}
        />
      </Stack>
     <Collapse in={isInteractive}>
     <SliderSetting
          label="activeInnerRadiusOffset"
          icon="innerPieRadius"
          minValue={0}
          step={5}
          maxValue={50}
          value={activeInnerRadiusOffset}
          updateValue={updateActiveInnerRadiusOffset}
          variableType="px"
        />
        <SliderSetting
          label="activeOuterRadiusOffset"
          icon="pieCornerRadius"
          minValue={0}
          step={5}
          maxValue={50}
          value={activeOuterRadiusOffset}
          updateValue={updateActiveOuterRadiusOffset}
          variableType="px"
        />
      </Collapse>
    </SettingContainer>
  )
}

export default PieInteractivitySettings;
import React from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Typography, Button, Stack, Box, IconButton, TextField, InputAdornment, Collapse } from "@mui/material";
import { useViewport } from "../../../Context/ViewportProvider";
import TranslationContent from "../../../Translations/TranslationContent";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { initializeChartData } from "../../../DataFactory/ChartData/initializeChartData";


const DiscountSection = ({ comparisonData, changeDiscount, restoreDiscounts }) => {
  const [showDiscountsSection, setShowDiscountsSection] = React.useState(true);
  const [discountsToDisplayCount, setDiscountsToDisplayCount] = React.useState(5);
  const [discounts, setDiscounts] = React.useState([]);
  const { width } = useViewport();

  const toggleDiscountShow = () => {
    setShowDiscountsSection(!showDiscountsSection);
  }

  const viewMoreDiscountsClick = () => {
    console.log(discounts)
    if(discountsToDisplayCount < discounts.length) {
      setDiscountsToDisplayCount(prev => prev + 5);
    }
  }

  const viewLessDiscountsClick = () => {
    if(discountsToDisplayCount > 5)
    setDiscountsToDisplayCount(prev => prev - 5);
  }

  React.useEffect(() => {
    let updatedDiscounts = [...discounts];

    let discountPercentageObject = comparisonData.data.find(entry => (Object.keys(entry)).find(key => key.split(":")[2] === "discount"));
    let discountPercentageKey = (Object.keys(discountPercentageObject)).find(key => key.split(":")[2] === "discount");

    if (comparisonData.filters.length > 0) {

      const filterData = async () => {
        let filteredData = await initializeChartData(comparisonData.data, comparisonData.filters);
    
        updatedDiscounts = filteredData.map(data => {
          let discount = {
            label: data.discountKey,
            discount: data[discountPercentageKey]
          }
          return discount;
        })
        setDiscounts(updatedDiscounts)

      }
      filterData();
    }

    if(comparisonData.filters.length === 0) {
    
      updatedDiscounts = comparisonData.data.map(data => {
        let discount = {
          label: data.discountKey,
          discount: data[discountPercentageKey]
        }
        return discount;
      })
      setDiscounts(updatedDiscounts)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comparisonData.filters, comparisonData.data]);

  return(
    <>
      <Grid xs={12} mt={0} sx={{borderBottom: showDiscountsSection ? "1px solid #fc2516" : ""}}>
        <Stack direction={width > 500 ? "row" : "column"} sx={{margin: "auto", alignItems: "center"}}>
          <Box sx={{display: "inline-flex"}}>
            <IconButton color="primary" onClick={() => toggleDiscountShow()}>
              {showDiscountsSection ? 
                <KeyboardArrowUpIcon />
              :
                <KeyboardArrowDownIcon />
              }
            </IconButton>
            <Typography sx={{marginTop: "auto", marginBottom: "auto"}} variant="subtitle2"><b><TranslationContent contentID="discount" /> %</b></Typography>
          </Box>          
          <Box sx={{marginRight: width > 500 ? 0 : "auto", marginLeft: width < 500 ? 0 : "auto"}}>
           
            <Button onClick={() => restoreDiscounts() } variant="outlined" size="small"><TranslationContent contentID="restore" /></Button>
          </Box>
        </Stack>
      </Grid>

      <Collapse in={showDiscountsSection} sx={{width: "100%"}}>
      <Grid xs={12} sx={{padding: "15px"}}>
            <Grid container spacing={2}>
              
              {discounts.map((discount, index) => 
              index < discountsToDisplayCount &&
                <Grid xs={12} md={6} xl={3} key={discount+index+discount.key}>
                  <TextField 
                    size="small"
                    fullWidth
                    label={discount.label}
                    value={discount.discount}
                    onChange={(event) => changeDiscount(event, discount.label)}
                    InputProps={{
                      endAdornment: <InputAdornment position="start">%</InputAdornment>,
                    }}
                  />
                </Grid>
              )}
              <Grid xs={12}>
          <Box sx={{textAlign: "center"}}>
            {discountsToDisplayCount > 5 &&
            <>
          <Button variant="outlined" size="small" sx={{mt: 1, minWidth: "200px"}} onClick={() => viewLessDiscountsClick()}><TranslationContent contentID="viewLess" /><KeyboardArrowUpIcon /></Button>
          <br />
          </>
            }
            {discountsToDisplayCount <= discounts.length && discounts.length > 5 &&
            <Button variant="outlined" size="small" sx={{mt: 1, minWidth: "200px"}} onClick={() => viewMoreDiscountsClick()}><TranslationContent contentID="viewMore" /><KeyboardArrowDownIcon /></Button>
            }
          </Box>
          </Grid>
            </Grid>

          
      </Grid>
      </Collapse>
      
    </>
  )
}

export default DiscountSection;
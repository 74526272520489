import React from "react";
import { Box, Button, Card, CardActionArea, CardContent, Stack, Typography, Paper } from "@mui/material";
import GlobalLayout from "../../Layouts/GlobalLayout";
import { SnackBarContext } from "../../Context/SnackbarProvider";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useNavigate } from "react-router-dom";
import TranslationContent from "../../Translations/TranslationContent";
import LoadingSpinner from "../Loading/LoadingSpinner";
import { Tokens } from "../../services/Tokens";
import compareServices from "../../services/compare";
import { logger } from "../../services/logger";
import { LanguageContext } from "../../Translations/LanguageProvider";
import ReplayIcon from '@mui/icons-material/Replay';
import GroupIcon from "../Utils/GlobalComponents/GroupIcon";
import { SessionContext, SessionDispatchContext } from "../../Context/SessionProvider";


const Home = () => {
  const [groups, setGroups] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const currentLang = React.useContext(LanguageContext);
  const session = React.useContext(SessionContext);
  const updateSession = React.useContext(SessionDispatchContext);
  const { addAlert } = React.useContext(SnackBarContext);
  const navigate = useNavigate();


  const fetchData = React.useRef(true);
  React.useEffect(() => {
    if(fetchData.current) {
      fetchData.current = false;
      fetchGroups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const fetchGroups = () => {
    setLoading(true);
    setError(false);
    Tokens().then((tokens) => {
      compareServices
      .getGroups(tokens.accessToken, tokens.idToken)
      .then(response => {
        logger("User's groups:");
        logger(response);
        let updatedGroups = [...groups];
        updatedGroups = [...response];
        setGroups(updatedGroups);
        if(response.some(group => group.role === "admin")) {
          let updatedSession = {...session};
          updatedSession.showAdminsTools = true;
          updateSession({type: "UPDATE_SESSION", value: updatedSession})
        }
        setLoading(false);
      })
      .catch(error => {
        logger(error);
        setError(true);
        setLoading(false);
        addAlert({message: "snackbarFetchingUsersFailed", type: "error"});
      })
    })
    .catch(error => {
      logger(error);
      setError(true);
      setLoading(false);
      addAlert({message: "snackbarSessionExpired", type: "error"});
    })
  }

  const handleSectionChange = (id) => {
    navigate("/compare/"+id);
  }

  if(loading && !error) {
    return(
      <GlobalLayout>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Paper sx={{padding: "7px"}} elevation={5}>
              <Typography color="primary" variant="subtitle1"><b><TranslationContent contentID="home" /></b></Typography>
            </Paper>
          </Grid>
        </Grid>
        <Box sx={{width: "100%", textAlign: "center", mt: 5}}>
          <LoadingSpinner />
        </Box>
      </GlobalLayout>
    )
  }
  if(!loading && error) {
    return(
      <GlobalLayout>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Paper sx={{padding: "7px"}} elevation={5}>
              <Typography color="primary" variant="subtitle1"><b><TranslationContent contentID="home" /></b></Typography>
            </Paper>
          </Grid>
        </Grid>
        <Box sx={{width: "100%", textAlign: "center", mt: 5}}>
          <Typography mb={5}><TranslationContent contentID="homeErrorOccured" /></Typography>
          <Button variant="outlined" onClick={() => {fetchGroups()}}><TranslationContent contentID="retry" /> <ReplayIcon /></Button>
        </Box>
      </GlobalLayout>
    )
  }

  if(!loading && !error) {
    return(
      <GlobalLayout>
        <Grid container spacing={2}>
          <Grid xs={12}>
            <Paper sx={{padding: "7px"}} elevation={5}>
              <Typography color="primary" variant="subtitle1"><b><TranslationContent contentID="home" /></b></Typography>
            </Paper>
          </Grid>
          {groups.map( group => (
            <Grid xs={12} md={6} xl={4} key={group.id}>
              <Card>
                <CardActionArea onClick={() => handleSectionChange(group.id)}>
                  <CardContent sx={{display: "flex"}}>
                    <Box sx={{width: "80%"}}>
                      <Stack>
                        <Typography><b>{group.name[currentLang.lang]} (<TranslationContent contentID={group.country} />)</b></Typography>
                        <Typography variant="caption" sx={{opacity: 0.6}}><TranslationContent contentID={group.role} /></Typography>
                      </Stack>
                    </Box>
                    <GroupIcon type={group.type} />
                    <br />
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </GlobalLayout>
    )
  }
}

export default Home;
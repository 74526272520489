import React from "react";
import { FormGroup, FormControlLabel, Radio } from "@mui/material";
import SettingContainer from "../../Settings/SettingContainer";


const LineIdentifierSettings = ({ labelButtons, selectedLabelButton, updateSelectedLabel, preferredIdentifier }) => {
  const [showSettings, setShowSettings] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState(false);
  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    if(labelButtons.length > 0 && !labelButtons.some(button => button.objectKey === preferredIdentifier)) {
      setOptions([...labelButtons, {objectKey: preferredIdentifier}]);
    }
    else {
      setOptions([...labelButtons])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[labelButtons])

  return(
    <SettingContainer
      open={showSettings}
      toggleOpen={() => setShowSettings(!showSettings)}
      label="identifier"
      dialogOpen={showDialog}
      toggleDialog={() => setShowDialog(!showDialog)}
    >
      <FormGroup sx={{ ml: 1.5 }}>
        {options.map((button, index) => 
          <FormControlLabel
            key={button.objectKey + index} 
            label={button.objectKey.split(":")[0]}
            control={
              <Radio 
                checked={selectedLabelButton === button.objectKey} 
                onChange={() => updateSelectedLabel(button.objectKey)}
                size="small"
              />
            }
          />
        )}
      </FormGroup>
    </SettingContainer>
  )
}

export default LineIdentifierSettings;
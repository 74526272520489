import React from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Typography, Button, Stack, IconButton, Collapse } from "@mui/material";
import Category from "./Category";
import TranslationContent from "../../../Translations/TranslationContent";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


const FilterSection = ({ comparisonData, addFilter, removeFilter, removeAllFilters }) => {
  const [showFiltersSection, setShowFiltersSection] = React.useState(true);

  const toggleFilterShow = () => {
    setShowFiltersSection(!showFiltersSection);
  }

  return(
    <>
      <Grid xs={12} mt={0} sx={{borderBottom: showFiltersSection ? "1px solid #fc2516" : ""}}>
        <Stack direction={"row"} sx={{alignItems: "center"}}>
          <IconButton color="primary" onClick={() => toggleFilterShow()}>
            {showFiltersSection ? 
              <KeyboardArrowUpIcon />
            :
              <KeyboardArrowDownIcon />
            }
          </IconButton>
          <Typography sx={{marginTop: "auto", marginBottom: "auto"}} variant="subtitle2"><b><TranslationContent contentID="filters" /></b></Typography>
          <Button onClick={() => removeAllFilters()} size="small" variant="outlined" sx={{marginRight: 0, marginLeft: "auto"}}><TranslationContent contentID="removeFilters" /></Button>
        </Stack>
      </Grid>
      <Collapse in={showFiltersSection} sx={{width: "100%"}}>
        <Grid xs={12} sx={{padding: "15px"}}>
          {comparisonData.uniqueCategories.map((category, index) => 
            <Category category={category} key={category+index} comparisonData={comparisonData} removeFilter={removeFilter} addFilter={addFilter} />
          )}
        </Grid>
      </Collapse>
    </>
  )
}

export default FilterSection;
import React from "react";
import DataManagementDialog from "../DataManagementDialog";
import { useParams } from "react-router-dom";
import { Tokens } from "../../../services/Tokens";
import { logger } from "../../../services/logger";
import compareServices from "../../../services/compare";
import { SnackBarContext } from "../../../Context/SnackbarProvider";
import { SessionContext } from "../../../Context/SessionProvider";
import { initializeData } from "../../../DataFactory/initializeData";
import "../loadingDots.css";
import CompareLoading from "./CompareLoading";
import CompareError from "./CompareError";
import CompareSuccess from "./CompareSuccess";
import GlobalLayout from "../../../Layouts/GlobalLayout";
import { useNavigate } from "react-router-dom";


const Compare = () => {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState(false);
  const session = React.useContext(SessionContext);
  const { addAlert } = React.useContext(SnackBarContext);
  const { id } = useParams();
  const [groupData, setGroupData] = React.useState({
    name: {
      fi: "",
      en: "",
    },
    role: "",
    type: "",
    country: "",
    saved: null,
    lastModifier: "",
  });

  const [comparisonData, setComparisonData] = React.useState(
    {
      //data: [...testData],
      
      //data: JSON.parse(JSON.stringify([...testData])),
      data: [],
      //filteredData: [],
      headers: 
        [
          /* Template
          {
            uniqueKey: "",
            name: "",
            category: "",
            type: "",
          },
          */
        ],
      uniqueCategories: [],
      defaultDiscounts: [],
      filters: 
        [
          /* Template, first for string Autocomplete filters, second for other types which use Checkbox
          {
            values: [],
            objectKey: "",
          },
          {
            objectKey: "",
            value: Type(),
          }
          */
        ],
        preferredIdentifier: "",
    }
  );

  const navigate = useNavigate();

  const fetchData = React.useRef(true);
  React.useEffect(() => {
    if(session.email.length < 1) {
      navigate("/");
      addAlert({message: "snackbarLoginToUseFiltersDesc", type: "info"});
      return;
    }
    if(fetchData.current) {
      fetchData.current = false;
      fetchComparisonData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const fetchComparisonData = async () => {
    setLoading(true);
    setError(false);
  
    try {
      const tokens = await Tokens();
      const response = await compareServices.getData(id, tokens.accessToken, tokens.idToken);
      logger(response);
      let updatedGroupData = { ...groupData };
      updatedGroupData.country = response.country;
      updatedGroupData.saved = response.saved;
      updatedGroupData.lastModifier = response.lastModifier;
      updatedGroupData.name = { ...response.name };
      updatedGroupData.type = response.type;
      updatedGroupData.role = response.role;
      setGroupData(updatedGroupData);
  
      let initializedData = await initializeData(response.data);
      let updatedState = {...comparisonData};

      updatedState.data = [...initializedData.data];
      updatedState.headers = [...initializedData.headers];
      updatedState.uniqueCategories = [...initializedData.categories];
      updatedState.defaultDiscounts = [...initializedData.defaultDiscounts];
      updatedState.filters = [...initializedData.filters];
      updatedState.preferredIdentifier = (response.preferredIdentifier);
      setComparisonData(updatedState);
      setLoading(false);

    } catch (error) {
      logger(error);
      logger(console.trace());
      setError(true);
      setLoading(false);
      addAlert({ message: "snackbarFetchingDataFailed", type: "error" });
    }
  };

  const uploadLocalData = async (data) => {
    setLoading(true);
    setError(false);
    toggleDialogShow(false);
    let updatedGroupData = {...groupData};
    updatedGroupData.country = "own";
    updatedGroupData.saved = Date.now();
    updatedGroupData.lastModifier = session.name;
    updatedGroupData.name = {fi: "Lokaalidata", en: "Local Data"};
    updatedGroupData.type = "local";
    updatedGroupData.role = "local";
    setGroupData(updatedGroupData);

    try {
      let initializedData = await initializeData(data);
      let updatedState = {...comparisonData};

      updatedState.data = [...initializedData.data];
      updatedState.headers = [...initializedData.headers];
      updatedState.uniqueCategories = [...initializedData.categories];
      updatedState.defaultDiscounts = [...initializedData.defaultDiscounts];
      updatedState.filters = [...initializedData.filters];
      setComparisonData(updatedState);
      setLoading(false);
    }
    catch (error) {
      logger(error);
      logger(console.trace());
      setError(true);
      setLoading(false);
    }
  }

  const uploadData = async (data) => {
    setLoading(true);
    setError(false);
    toggleDialogShow(false);

    try {
      const tokens = await Tokens();
      const response = await compareServices.updateData(id, data, tokens.accessToken, tokens.idToken);
      logger(response);
      let updatedGroupData = {...groupData};
      updatedGroupData.country = response.country;
      updatedGroupData.saved = response.saved;
      updatedGroupData.lastModifier = response.lastModifier;
      updatedGroupData.name = {...response.name};
      updatedGroupData.type = response.type;
      updatedGroupData.role = response.role;
      setGroupData(updatedGroupData);

      let initializedData = await initializeData(response.data.data);
      let updatedState = {...comparisonData};

      updatedState.data = [...initializedData.data];
      updatedState.headers = [...initializedData.headers];
      updatedState.uniqueCategories = [...initializedData.categories];
      updatedState.defaultDiscounts = [...initializedData.defaultDiscounts];
      updatedState.filters = [...initializedData.filters];
      setComparisonData(updatedState);
      setLoading(false);
    }
    catch(error) {
      logger(error);
      logger(console.trace());
      setError(true);
      setLoading(false);
      addAlert({message: "snackbarFailedToUploadData", type: "error"});
    }
  };

  const addFilter = (objectKey, filters) => {
    let updatedState = {...comparisonData};

    const foundFilter = updatedState.filters.find(filter => filter.objectKey === objectKey);
    
    if(typeof foundFilter !== 'undefined') {
      if(filters.length > foundFilter.values.length) {
        foundFilter.values = [...new Set(foundFilter.values.concat(filters))]
      }
      else {
        foundFilter.values = foundFilter.values.filter(oldFilter => filters.includes(oldFilter));
      }
    }
    else {
      let categoryFirstTimeFilter = {
        objectKey: objectKey,
        values: filters,
      }
      updatedState.filters = [...updatedState.filters, categoryFirstTimeFilter]
    }
    updatedState.filters = updatedState.filters.filter(filter => filter.values.length > 0);
    setComparisonData(updatedState)
  }

  const removeFilter = (objectKey) => {
    let updatedFilters = {...comparisonData};
    updatedFilters.filters = [...comparisonData.filters]
    updatedFilters.filters = updatedFilters.filters.filter(filter => filter.objectKey !== objectKey);
    setComparisonData(updatedFilters);
  }

  const removeAllFilters = () => {
    let removedFilters = {...comparisonData};
    removedFilters.filters = removedFilters.filters.slice(removedFilters.filters.length)
    setComparisonData(removedFilters);
  }

  const restoreDiscounts = () => {
    let discountsRestored = [...comparisonData.data];
    let discountPercentageObject = comparisonData.data.find(entry => (Object.keys(entry)).find(key => key.split(":")[2] === "discount"));
    let discountPercentageKey = (Object.keys(discountPercentageObject)).find(key => key.split(":")[2] === "discount");

    discountsRestored = discountsRestored.map(row => {
      let defaultDiscount = comparisonData.defaultDiscounts.find(discount => discount.key === row.discountKey);
      row[discountPercentageKey] = defaultDiscount.discountPercentage;
      return row;
      // The new prices will be calculated in the chart data updates!
      // This will only update the discount that will be used for chart data calculations
    });
    let updatedState = {...comparisonData};
    updatedState.data = [...discountsRestored];
    setComparisonData(updatedState);
  }

  const changeDiscount = async (event, key) => {

    let updatedDiscounts = [...comparisonData.data];

    let discountPercentageObject = comparisonData.data.find(entry => (Object.keys(entry)).find(key => key.split(":")[2] === "discount"));
    let discountPercentageKey = (Object.keys(discountPercentageObject)).find(key => key.split(":")[2] === "discount");

    let currentIndex = updatedDiscounts.findIndex(row => row.discountKey === key);
    
    let formalizedDiscount = event.target.value.replace(",",".");
    updatedDiscounts[currentIndex][discountPercentageKey] = formalizedDiscount;

    let updatedState = {...comparisonData};
    updatedState.data = [...updatedDiscounts];
    setComparisonData(updatedState);
  }

  const toggleDialogShow = () => {
    setShowDialog(!showDialog);
  }

  return(
    <GlobalLayout>
      <DataManagementDialog 
        showDialog={showDialog}
        toggleDialogShow={toggleDialogShow}
        comparisonData={comparisonData}
        role={groupData.role}
        uploadLocalData={uploadLocalData}
        uploadData={uploadData}
      />
      <CompareLoading 
        loading={loading} 
        error={error} 
      />
      <CompareError 
        error={error}
        toggleDialogShow={() => setShowDialog(!showDialog)} 
        fetchComparisonData={fetchComparisonData}
      />
      <CompareSuccess
        loading={loading} 
        error={error}
        toggleDialogShow={() => setShowDialog(!showDialog)} 
        comparisonData={comparisonData}
        addFilter={addFilter}
        removeFilter={removeFilter}
        removeAllFilters={removeAllFilters}
        changeDiscount={changeDiscount}
        changeDiscountKey={changeDiscount}
        restoreDiscounts={restoreDiscounts}
        groupData={groupData}
      />
    </GlobalLayout>
  )
}

export default Compare;
import React from "react";
import { useViewport } from "../../../../../Context/ViewportProvider";
import { Stack, Collapse } from "@mui/material";
import SettingContainer from "../../Settings/SettingContainer";
import SliderSetting from "../../Settings/SliderSetting";
import SwitchSetting from "../../Settings/SwitchSetting";
import SelectSetting from "../../Settings/SelectSetting";
import RadioSetting from "../../Settings/RadioSetting";
import SettingDivider from "../../Settings/SettingDivider";


const LineLegendSettings = ({ 
  showLegend,
  setShowLegend,
  legendPosition,
  setLegendPosition,
  legendLayout,
  setLegendLayout,
  legendTranslateX,
  setLegendTranslateX,
  legendTranslateY,
  setLegendTranslateY,
  legendWidth,
  setLegendWidth,
  legendHeight,
  setLegendHeigth,
  legendSpacing,
  setLegendSpacing,
  symbolSize,
  setSymbolSize,
  justifyLegend,
  setJustifyLegend,
  legendDirection,
  setLegendDirection
}) => {
  const [showSettings, setShowSettings] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState(false);
  const { width } = useViewport();

  return(
    <SettingContainer
      label="legends" 
      open={showSettings}
      toggleOpen={() => setShowSettings(!showSettings)}
      dialogOpen={showDialog}
      toggleDialog={() => setShowDialog(!showDialog)}
    >
      <Stack direction={width > 500 ? "row" : "column"} spacing={2} sx={{alignItems: "flex-start", pb: 2}}>
        <SwitchSetting 
          label="show"
          value={showLegend}
          handleChange={setShowLegend}  
        />
        <SelectSetting
          disabled={!showLegend}
          label="position"
          value={legendPosition}
          handleChange={(value) => setLegendPosition(value)}
          optionKey="legendPositionOptions"
        />
        <RadioSetting
          disabled={!showLegend}
          label="layout"
          value={legendLayout}
          handleChange={(value) => {setLegendLayout(value)}}
          optionKey="legendLayoutOptions"
        />
      </Stack>
      <Collapse in={showLegend}>
        <Stack direction={width > 500 ? "row" : "column"} spacing={2} sx={{alignItems: "flex-start", pb: 2}}>
          <SwitchSetting 
            label="justify"
            value={justifyLegend}
            handleChange={setJustifyLegend}  
          />
          <SelectSetting 
            label="direction"
            value={legendDirection}
            handleChange={(value) => setLegendDirection(value)}
            optionKey="legendDirectionOptions"
          />
        </Stack>
        <SettingDivider />
        <SliderSetting
          label="translateX"
          icon="translateX"
          minValue={-500}
          maxValue={500}
          value={legendTranslateX}
          updateValue={(value) => setLegendTranslateX(value)}
          variableType="px"
        />
        <SettingDivider />
        <SliderSetting
          label="translateY"
          icon="translateY"
          minValue={-500}
          maxValue={500}
          value={legendTranslateY}
          updateValue={(value) => setLegendTranslateY(value)}
          variableType="px"
        />
        <SettingDivider />
        <SliderSetting 
          label="itemWidth"
          icon="itemWidth"
          minValue={10}
          maxValue={450}
          value={legendWidth}
          updateValue={(value) => setLegendWidth(value)}
          variableType="px"
        />
        <SettingDivider />
        <SliderSetting 
          label="itemHeight"
          icon="itemHeight"
          minValue={10}
          maxValue={200}
          value={legendHeight}
          updateValue={(value) => setLegendHeigth(value)}
          variableType="px"
        />
        <SettingDivider />
        <SliderSetting 
          label="itemSpacing"
          icon="itemSpacing"
          minValue={0}
          maxValue={60}
          step={5}
          value={legendSpacing}
          updateValue={(value) => setLegendSpacing(value)}
          variableType="px"
        />
        <SettingDivider />
        <SliderSetting 
          label="symbolSize"
          icon="symbolSize"
          minValue={2}
          maxValue={60}
          step={5}
          value={symbolSize}
          updateValue={(value) => setSymbolSize(value)}
          variableType="px"
        />
      </Collapse>
    </SettingContainer>
  )
}


export default LineLegendSettings;
import { logger } from "../../services/logger";

export const createInitDiscounts = async (data) => {

  let calculatedDiscounts = [];

  logger("Starting to calculate prices with discounts...");

  calculatedDiscounts = await Promise.all(data.map( async (row) => {
    let currentObjectKeysWithValues = Object.keys(row);
    let currentDiscount = currentObjectKeysWithValues.find(key => key.split(":")[2] === "discount");
    if(!currentDiscount) {
      return row;
    }
    if(row[currentDiscount] <= 0) {
      return row;
    }
    
    for(const header of currentObjectKeysWithValues) {
      if(header.split(":")[1] === "price" && header.split(":")[2] !== "discount") {
        row[header] = parseFloat((row[header] * ((100 - row[currentDiscount]) / 100)).toFixed(2));
      }
    }
    return row;
  }));

  return calculatedDiscounts;
}
import { logger } from "../../services/logger";
import { isValidNumber, isValidDiscount, isValidBoolean, isValidDate } from "../../Validators/helperFunctions";
import { formatNumber } from "../FormatData/formatNumber";
import { formatDiscount } from "../FormatData/formatDiscount";
import { formatBoolean } from "../FormatData/formatBoolean";
import { formatDate } from "../FormatData/formatDate";


export const createDataValues = async (data, headers) => {
  logger("Starting to create data values...");
  let initializedData = [];

  const productCount = {};

    for(const row of data) {

    let currentObjectKeysWithValues = Object.keys(row);
    for(const header of headers) {
      if(currentObjectKeysWithValues.some(key => key === header.uniqueKey)) {
        switch(header.type.trim()) {

          case "string":
            if(!typeof row[header.uniqueKey] === "string") {
              row[header.uniqueKey] = "Invalid value";
              break;
            }
            if(row[header.uniqueKey].trim().length < 1) {
              row[header.uniqueKey] = "No value";
              break;
            }
            break;

          case "number":
            if(!isValidNumber(row[header.uniqueKey])) {
              row[header.uniqueKey] = 0;
              break;
            }

            let initializedNumber = await formatNumber(row[header.uniqueKey]);
            row[header.uniqueKey] = initializedNumber;
            break;
            
          case "discount":
            if(!isValidDiscount(row[header.uniqueKey])) {
              row[header.uniqueKey] = 0;
              break;
            }
            row[header.uniqueKey] = await formatDiscount(row[header.uniqueKey]);
            break;

          case "boolean":
            if(!isValidBoolean(row[header.uniqueKey])) {
              row[header.uniqueKey] = false;
              break;
            }
            row[header.uniqueKey] = await formatBoolean(row[header.uniqueKey]);
            break;

          case "date":
            if(!isValidDate(row[header.uniqueKey])) {
              row[header.uniqueKey] = "Date format not valid";
              break;
            }
            row[header.uniqueKey] = await formatDate(row[header.uniqueKey]);
            break;

          default:
            row[header.uniqueKey] = "Value type missing from header";
            break;
        }
      }
      else {
        switch(header.type) {
          case "string":
            row[header.uniqueKey] = "No value";
            break;
          case "number":
            row[header.uniqueKey] = 0;
            break;
          case "discount":
            row[header.uniqueKey] = 0;
            break;
          case "boolean":
            row[header.uniqueKey] = false;
            break;
          case "date":
            row[header.uniqueKey] = "No date specified";
            break;
          default:
            row[header.uniqueKey] = "Value type missing from header";
            break;
        }
      }
    }

    let productNameKey = "";
    let currentObjectKeys = Object.keys(row);
    if(currentObjectKeys.includes("Tuotenimi:basic:string")) {
      productNameKey = "Tuotenimi:basic:string";
    }
    if(currentObjectKeys.includes("Nazwa produktu:basic:string")) {
      productNameKey = "Nazwa produktu:basic:string";
    }

    if(!productCount[row[productNameKey]]) {
      productCount[row[productNameKey]] = 0;
    }

    const indexedProductName = productCount[row[productNameKey]] === 0
    ? row[productNameKey]
    :
    `${row[productNameKey]} (${productCount[row[productNameKey]]})`;

    // This should be done some other way so that we don't need to hardcode the productname in the source code. One possibility is to include it in the data in db (which key will be the identifier)
    //Tuotenimi:basic:string
    //Nazwa produktu:basic:string

    row["discountKey"] = indexedProductName;

    productCount[row[productNameKey]]++;

    initializedData.push(row);
  };

  logger(`Created ${initializedData.length} data values`);

  logger("Lets filter values that has no product name");
  let filteredInitializedData = initializedData.filter(value => !(value["Nazwa produktu:basic:string"] === "No value" || value["Tuotenimi:basic:string"] === "No value"));

  logger(`Created ${initializedData.length} original data values. The count of data values that had product name ${filteredInitializedData.length}. Filtered ${initializedData.length - filteredInitializedData.length} values.`);
  
  return filteredInitializedData;
};
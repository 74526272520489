import { logger } from "../../services/logger"

export const discountCalculator = async ( data ) => {
  logger("Starting to calculate discounts for chart / table data...");
  
  let discountedData = [];

  try {
    
    discountedData = data.map(dataEntry => {
      let currentObjectKeys = Object.keys(dataEntry);
      let priceObjectKeys = currentObjectKeys.filter(key => key.split(":")[1] === "price" && key.split(":")[2] !== "discount");
      let discountKey = currentObjectKeys.find(key => key.split(":")[2] === "discount");

      if(!discountKey) {
        return dataEntry;
      }
    
      for(const priceKey of priceObjectKeys) {
        dataEntry[priceKey] = parseFloat((dataEntry[priceKey] * ((100 - dataEntry[discountKey]) / 100)).toFixed(2));
      } 
    
      return dataEntry;
    })

    logger("Discounts calculated:");
    logger(discountedData);
  }
  
  catch (error) {
    logger(error);
    logger(console.trace());
  }

  return discountedData;
}
import React from "react";
import SettingContainer from "../../Settings/SettingContainer";
import SelectSetting from "../../Settings/SelectSetting";
import SettingDivider from "../../Settings/SettingDivider";
import TranslationContent from "../../../../../Translations/TranslationContent";
import { useViewport } from "../../../../../Context/ViewportProvider";
import { Collapse, Stack, Typography } from "@mui/material";
import SliderSetting from "../../Settings/SliderSetting";
import SwitchSetting from "../../Settings/SwitchSetting";


const LineStyleSettings = 
  ({
    curve,
    updateCurve,
    curveThickness,
    updateCurveThickness,
    chartColors,
    updateChartColors,

    enableArea,
    updateEnableArea,
    areaBaselineValue,
    updateAreaBaselineValue,
    areaOpacity,
    updateAreaOpacity,

    enablePoints,
    updateEnablePoints,
    pointSize,
    updatePointSize,
    enablePointLabels,
    updateEnablePointLabels,
    pointLabelYOffset,
    updatePointLabelYOffset,
    yData
  }) => {
  const [showSettings, setShowSettings] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState(false);
  const { width } = useViewport();
  const [yMin, setYMin] = React.useState(0);
  const [yMax, setYMax] = React.useState(10);


  React.useEffect(() => {
    calculateHighestValue(yData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  const calculateHighestValue = (lineData) => {
    let allValues = [];

    for(let i = 0; i < lineData.length; i++) {
      for(let x = 0; x < lineData[i].data.length; x++) {
        allValues.push(Math.round(lineData[i].data[x].y));
      }
    }

    let largestNumber = Math.max(...allValues);
    setYMax(largestNumber);

    let smallestNumber = Math.min(...allValues);
    if(smallestNumber < 0) {
      setYMin(smallestNumber);
    }
    else {
      setYMin(0);
    }
  }

  return(
    <SettingContainer
      open={showSettings}
      toggleOpen={() => setShowSettings(!showSettings)}
      label="style"
      dialogOpen={showDialog}
      toggleDialog={() => setShowDialog(!showDialog)}
    >
      <Stack direction={width > 500 ? "row" : "column"} spacing={2} sx={{alignItems: "flex-start", pb: 2}}>
        <Typography variant="caption" sx={{alignSelf: "flex-start", ml: 1.5, width: "70px"}}>
          <b>
            <TranslationContent contentID="curve" />
          </b>
        </Typography>
        <SelectSetting 
          label="curveInterpolation"
          value={curve}
          handleChange={updateCurve}
          optionKey="curveOptions"
        />
      </Stack>
      <div style={{height: "10px"}} />
      <SliderSetting
        label="lineWidth"
        icon="lineWeight"
        minValue={0}
        maxValue={20}
        step={1}
        value={curveThickness}
        updateWithTwo={false}
        type="containerWidth"
        updateValue={updateCurveThickness}
        variableType="px"
      />
      <SettingDivider />
      <div style={{height: "10px"}} />
      <Stack direction={width > 500 ? "row" : "column"} spacing={2} sx={{alignItems: "flex-start", pb: 2}}>
        <Typography variant="caption" sx={{alignSelf: "flex-start", ml: 1.5, width: "70px"}}>
          <b>
            <TranslationContent contentID="colors" />
          </b>
        </Typography>
        <SelectSetting 
          label="colorScheme"
          value={chartColors}
          handleChange={updateChartColors}
          optionKey="colorOptions"
        />
      </Stack>
      <SettingDivider />
      <div style={{height: "10px"}} />
      <Stack direction={width > 500 ? "row" : "column"} spacing={2} sx={{alignItems: "flex-start", pb: 2}}>
        <Typography variant="caption" sx={{alignSelf: "flex-start", ml: 1.5, width: "70px", mr: "-10px"}}>
          <b>
            <TranslationContent contentID="area" />
          </b>
        </Typography>
        <SwitchSetting
          label="active"
          value={enableArea}
          handleChange={updateEnableArea}  
        />
      </Stack>
      <Collapse in={enableArea}>
        <SliderSetting
          label="baselineValue"
          icon="baseline"
          minValue={yMin}
          maxValue={yMax}
          step={1}
          value={areaBaselineValue}
          updateWithTwo={false}
          type=""
          updateValue={updateAreaBaselineValue}
          variableType=""
        />
        <SliderSetting
          label="opacity"
          icon="opacity"
          minValue={0}
          maxValue={1}
          step={0.1}
          value={areaOpacity}
          updateWithTwo={false}
          type=""
          updateValue={updateAreaOpacity}
          variableType=""
        />
      </Collapse>
      <SettingDivider />
      <div style={{height: "10px"}} />
      <Stack direction={width > 500 ? "row" : "column"} spacing={2} sx={{alignItems: "flex-start", pb: 2}}>
        <Typography variant="caption" sx={{alignSelf: "flex-start", ml: 1.5, width: "70px", mr: "-10px"}}>
          <b>
            <TranslationContent contentID="points" />
          </b>
        </Typography>
        <SwitchSetting
          label="show"
          value={enablePoints}
          handleChange={updateEnablePoints}  
        />
      </Stack>
      <Collapse in={enablePoints}>
        <SliderSetting
          label="pointSize"
          icon="symbolSize"
          minValue={2}
          maxValue={20}
          step={1}
          value={pointSize}
          updateWithTwo={false}
          type=""
          updateValue={updatePointSize}
          variableType="px"
        />
        <div style={{height: "20px"}} />
        <Stack direction={width > 500 ? "row" : "column"} spacing={2} sx={{alignItems: "flex-start", pb: 2}}>
          <Typography variant="caption" sx={{alignSelf: "flex-start", ml: 1.5, width: "70px", mr: "-10px"}}>
            <b>
              <TranslationContent contentID="value" />
            </b>
          </Typography>
          <SwitchSetting
            label="show"
            value={enablePointLabels}
            handleChange={updateEnablePointLabels}  
          />
        </Stack>
        <Collapse in={enablePointLabels}>
          <SliderSetting
            label="yPointOffset"
            icon="yOffset"
            minValue={-24}
            maxValue={24}
            step={1}
            value={pointLabelYOffset}
            updateWithTwo={false}
            type=""
            updateValue={updatePointLabelYOffset}
            variableType="px"
          />
        </Collapse>
      </Collapse>
    </SettingContainer>
  )
}

export default LineStyleSettings
import React from "react";
import { useViewport } from "../../../../../Context/ViewportProvider";
import SettingContainer from "../../Settings/SettingContainer";
import { Collapse, Stack, Typography, Box, Divider } from "@mui/material";
import TranslationContent from "../../../../../Translations/TranslationContent";
import SelectSetting from "../../Settings/SelectSetting";
import SwitchSetting from "../../Settings/SwitchSetting";
import SettingDivider from "../../Settings/SettingDivider";


const LineInteractivitySettings = 
  ({
    isInteractive,
    updateIsInteractive,
    useMesh,
    updateUseMesh,
    debugMesh,
    updateDebugMesh,
    debugSlices,
    updateDebugSlices,
    enableSlices,
    updateEnableSlices,
    enableCrosshair,
    updateEnableCrosshair,
    enableTouchCrosshair,
    updateEnableTouchCrosshair,
    crosshairType,
    updateCrosshairType,
  }) => {
  const [showSettings, setShowSettings] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState(false);
  const { width } = useViewport();

  return(
    <SettingContainer
      open={showSettings}
      toggleOpen={() => setShowSettings(!showSettings)}
      label="interactivity"
      dialogOpen={showDialog}
      toggleDialog={() => setShowDialog(!showDialog)}
    >
      <Stack direction={width > 500 ? "row" : "column"} spacing={2} sx={{alignItems: "flex-start", pb: 2, ml: 0.5}}>
        <SwitchSetting
          label="active"
          value={isInteractive}
          handleChange={updateIsInteractive}  
        />
        <Divider orientation="vertical" flexItem />

        <SwitchSetting
          label="voronoiMesh"
          value={useMesh}
          handleChange={updateUseMesh}  
        />
        <SwitchSetting
          label="highlightVoronoiMesh"
          value={debugMesh}
          handleChange={updateDebugMesh}  
        />
      </Stack>
      <Box sx={{ml: 1.5}}>
      <Typography variant="caption" sx={{opacity: 0.8}}><TranslationContent contentID="voronoiDesc" /></Typography>
      </Box>
      <Collapse in={true}>
      <SettingDivider />
      <div style={{height: "10px"}} />
      <Stack direction={width > 500 ? "row" : "column"} spacing={2} sx={{alignItems: "flex-start", pb: 2, ml: 0.5}}>
        <Typography variant="caption" sx={{alignSelf: "flex-start", ml: 1.5, width: "70px"}}><b><TranslationContent contentID="crosshair" /></b></Typography>
        <SwitchSetting
          label="active"
          value={enableCrosshair}
          handleChange={updateEnableCrosshair}  
        />
        <SwitchSetting
          label="crosshairTouchEnable"
          value={enableTouchCrosshair}
          handleChange={updateEnableTouchCrosshair}  
        />
        
      </Stack>
      <Stack direction={width > 500 ? "row" : "column"} spacing={2} sx={{alignItems: "flex-start", pb: 2, ml: 0.5}}>
        <Box sx={{alignSelf: "flex-start", ml: 1.5, width: "80px"}} />
        <SelectSetting 
          label="crosshairType"
          value={crosshairType}
          handleChange={updateCrosshairType}
          optionKey="crosshairTypeOptions"
        />
        </Stack>
        <div style={{height: "10px"}} />
        <SettingDivider />
        <div style={{height: "10px"}} />
        <Stack direction={width > 500 ? "row" : "column"} spacing={3} sx={{alignItems: "flex-start", pb: 2, ml: 0.5}}>
        <Typography variant="caption" sx={{alignSelf: "flex-start", ml: 1.5, width: "70px"}}><b><TranslationContent contentID="productTooltip" /></b></Typography>
        <SelectSetting 
          label="infoType"
          value={enableSlices}
          handleChange={updateEnableSlices}
          optionKey="tooltipTypeOptions"
        />
        <SwitchSetting
          label="drawHelpers"
          value={debugSlices}
          handleChange={updateDebugSlices}  
        />
          <div style={{height: "10px"}} />
      </Stack>
        
      </Collapse>
    </SettingContainer>
  )
}

export default LineInteractivitySettings;
import React from "react";
import { useViewport } from "../../../../../Context/ViewportProvider";
import SettingContainer from "../../Settings/SettingContainer";
import { Stack} from "@mui/material";
import SwitchSetting from "../../Settings/SwitchSetting";


const BarInteractivitySettings = 
  ({
    isInteractive,
    updateIsInteractive,
    customTooltip,
    updateCustomTooltip
  }) => {
  const [showSettings, setShowSettings] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState(false);
  const { width } = useViewport();

  return(
    <SettingContainer
      open={showSettings}
      toggleOpen={() => setShowSettings(!showSettings)}
      label="interactivity"
      dialogOpen={showDialog}
      toggleDialog={() => setShowDialog(!showDialog)}
    >
      <Stack direction={width > 500 ? "row" : "column"} spacing={2} sx={{alignItems: "flex-start", pb: 2, ml: 0.5}}>
        <SwitchSetting
          label="active"
          value={isInteractive}
          handleChange={updateIsInteractive}  
        />
        <SwitchSetting
          label="tooltipWithAllInfo"
          value={customTooltip}
          handleChange={updateCustomTooltip}
          disabled={!isInteractive}
        />
      </Stack>
    </SettingContainer>
  )
}

export default BarInteractivitySettings;
import React from "react";
import SettingContainer from "../../Settings/SettingContainer";
import SelectSetting from "../../Settings/SelectSetting";
import SettingDivider from "../../Settings/SettingDivider";
import TranslationContent from "../../../../../Translations/TranslationContent";
import { useViewport } from "../../../../../Context/ViewportProvider";
import { Stack, Typography } from "@mui/material";
import SliderSetting from "../../Settings/SliderSetting";
import SwitchSetting from "../../Settings/SwitchSetting";
import RadioSetting from "../../Settings/RadioSetting";


const BarStyleSettings = 
  ({
    chartColors,
    updateChartColors,
    hightLightRuukki,
    updateHighlightRuukki,
    groupMode,
    updateGroupMode,
    barLayout,
    updateBarLayout,
    reverse,
    updateReverse,
    padding,
    updatePadding,
    innerPadding,
    updateInnerPadding,
    borderRadius,
    updateBorderRadius,
    borderWidth,
    updateBorderWidth
  }) => {
  const [showSettings, setShowSettings] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState(false);
  const { width } = useViewport();

  return(
    <SettingContainer
      open={showSettings}
      toggleOpen={() => setShowSettings(!showSettings)}
      label="style"
      dialogOpen={showDialog}
      toggleDialog={() => setShowDialog(!showDialog)}
    >
      <Stack direction={width > 500 ? "row" : "column"} spacing={2} sx={{alignItems: "flex-start", pb: 2}}>
        <Typography variant="caption" sx={{alignSelf: "flex-start", ml: 1.5, width: "70px"}}>
          <b>
            <TranslationContent contentID="colors" />
          </b>
        </Typography>
        <SelectSetting 
          label="colorScheme"
          value={chartColors}
          handleChange={updateChartColors}
          optionKey="colorOptions"
        />
        <SwitchSetting 
          label="highlightRuukki"
          value={hightLightRuukki}
          handleChange={updateHighlightRuukki}  
        />
      </Stack>
      <SettingDivider />
      <div style={{height: "10px"}} />
      <Stack direction={width > 500 ? "row" : "column"} spacing={2} sx={{alignItems: "flex-start", pb: 2}}>
        <Typography variant="caption" sx={{alignSelf: "flex-start", ml: 1.5, width: "70px"}}>
          <b>
            <TranslationContent contentID="bars" />
          </b>
        </Typography>
        <RadioSetting
          label="groupMode"
          value={groupMode}
          handleChange={(value) => {updateGroupMode(value)}}
          optionKey="barGroupModeOptions"
        />
        <RadioSetting
          label="layout"
          value={barLayout}
          handleChange={(value) => {updateBarLayout(value)}}
          optionKey="barLayoutOptions"
        />
         <SwitchSetting 
          label="reverse"
          value={reverse}
          handleChange={updateReverse}  
        />
      </Stack>
      <SliderSetting
        label="barPadding"
        icon="barPadding"
        minValue={0}
        maxValue={0.9}
        step={0.1}
        value={padding}
        updateValue={updatePadding}
        variableType=""
      />
      <SliderSetting
        label="barInnerPadding"
        icon="barInnerPadding"
        minValue={0}
        maxValue={10}
        step={1}
        value={innerPadding}
        updateValue={updateInnerPadding}
        variableType="px"
      />
      <SliderSetting
        label="borderRadius"
        icon="barBorderRadius"
        minValue={0}
        maxValue={36}
        step={2}
        value={borderRadius}
        updateValue={updateBorderRadius}
        variableType="px"
      />
      <SliderSetting
        label="borderWidth"
        icon="barBorderWidth"
        minValue={0}
        maxValue={20}
        step={1}
        value={borderWidth}
        updateValue={updateBorderWidth}
        variableType="px"
      />
    </SettingContainer>
  )
}

export default BarStyleSettings;
import React from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Divider, Typography, Stack, IconButton } from "@mui/material";
import Filter from "./Filter";
import PossibleTranslationContent from "../../../Translations/PossibleTranslationContent";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const Category = ({ category, removeFilter, comparisonData, addFilter }) => {
  const [viewCategory, setViewCategory] = React.useState(true);

  return(
    <Grid container columnSpacing={2} rowSpacing={0.2}>
      <Grid xs={12} mb={1} mt={1} ml={0}>
      <Stack direction={"row"} sx={{alignItems: "center", p: 0, width: "100%"}}>
          <IconButton color="primary" onClick={() => setViewCategory(!viewCategory)}>
            {viewCategory ? 
              <KeyboardArrowUpIcon />
            :
              <KeyboardArrowDownIcon />
            }
          </IconButton>
        <Typography variant="body2">
          <b><PossibleTranslationContent contentID={category}/></b>
        </Typography>
        </Stack>
      </Grid>
      {
      comparisonData.headers.map((entry, index) => entry.category === category && viewCategory &&
        <Grid xs={12} sm={6} md={4} xl={3} key={category + index} pl={2.5} pb={entry.type === "string" ? 1 : 0}>
          <Filter
            objectKey={entry.uniqueKey}
            entry={entry}
            removeFilter={removeFilter}
            comparisonData={comparisonData}
            addFilter={addFilter}
          />
        </Grid>
      )
      }
      <Grid xs={12} mt={1} mb={1}><Divider /></Grid>
    </Grid>
  )
}

export default Category;
import { logger } from "../../services/logger";

export const createDefaultDiscounts = async (data) => {
  let defaultDiscounts = [];

  logger("Starting to create default discounts...");

  if(data) {
    defaultDiscounts = await Promise.all(data.map(row => {
      let currentObjectKeysWithValues = Object.keys(row);
      let defaultDiscount = currentObjectKeysWithValues.find(key => key.split(":")[2] === "discount");

      let defaultDiscountObject = {
        key: row.discountKey,
        discountPercentage: row[defaultDiscount]
      }
      return defaultDiscountObject;
    }))
    logger(defaultDiscounts);
  }
  return defaultDiscounts;
}

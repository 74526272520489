import React from "react";
import { Typography, Divider, Paper, Stack } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import BooleanValue from "../../../Utils/GlobalComponents/BooleanValue";
import { useViewport } from "../../../../Context/ViewportProvider";


const BarChartTooltip = ({ /*id, value, index,*/ indexValue, data, /*label*/x, y }) => {
  const { width, /*height*/ } = useViewport();
  const [tooltipWidth, setTooltipWidth] = React.useState(width);
  const [tooltipLeft, setTooltipLeft] = React.useState(0);
  const [tooltipRight, setTooltipRight] = React.useState(0);

  React.useEffect(() => {
    // For mobile screens
    if(width <= 500) {
      setTooltipWidth(width * 0.7)
    }

    // Pienille näytöille / ikkunoille
    if(width > 500 && width <= 1000) {
      if(width / 2 - 90 > x) {
        setTooltipLeft(150);
        setTooltipRight("auto");
      }

      if(width / 2  < x + 90 ) {
        setTooltipRight(150);
        setTooltipLeft("auto");
      }
      setTooltipWidth(width * 0.65)
    }

    // Suurilla ja keskisuurille näytöille / ikkunoille
    if(width > 1000) {
      if(width / 2 > x) {
        setTooltipLeft(150);
        setTooltipRight("auto");
      }

      if(width / 2 < x) {
        setTooltipRight(250);
        setTooltipLeft("auto");
      }

      if(width > 900) {
        setTooltipWidth(600);
      }
      else {
      setTooltipWidth(width * 0.6)
      }
    }
   
  }, [x, y, width]);


  return(
    <Paper 
      sx={{
        position: 'relative',
        left: tooltipLeft+"px",
        right: tooltipRight+"px",
        width: tooltipWidth,
        padding: '10px',
        borderRadius: '4px',
        pointerEvents: 'none',
        textAlign: "left"
      }}
    >
      <Typography><b>{indexValue}</b></Typography>
      <Divider />
      <Grid container columnSpacing={1} rowSpacing={1}>
        {Object.keys(data).map(objectKey => 
        objectKey !== "discountKey" &&
          <ObjectItem key={objectKey} objectKey={objectKey} data={data}/>
        )}
      </Grid>
    </Paper>
  )
}

export default BarChartTooltip;


const ValueTypography = ({value}) => {
  if(typeof value === "boolean") {
    return <BooleanValue value={value} />
  }
  if(value !== "No value" && value !== "Incorrect value type") {
    return(
      <Typography variant="caption" sx={{opacity: 0.9}}>{value}</Typography>
    )
  }
  if(value === "No value") {
    return(
      <Typography variant="caption" sx={{opacity: 0.5}}><i>{value}</i></Typography>
    )
  }
  if(value === "Incorrect value type") {
    return(
      <Typography variant="caption" color="error">{value}</Typography>
    )
  }
  
}

const ObjectItem = ({ data, objectKey }) => {
  return(
      <Grid xs={12} md={4}>
      <Stack direction="column">
        <Typography variant="caption"><b>{objectKey.split(":")[0]}</b></Typography>
        <ValueTypography value={data[objectKey]} />
      </Stack>
      </Grid>
  )
}
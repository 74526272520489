import React from "react";
import { Typography, Divider, Menu, Button, MenuItem, Tooltip } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DownloadIcon from '@mui/icons-material/Download';
import { downloadImage } from "../../../utils/download";
import TranslationContent from "../../../Translations/TranslationContent";
import { ThemeModeStateContext } from "../../../Theme/ThemeProvider";


const DownloadMenu = ({ domElement, disabled }) => {
  const currentTheme = React.useContext(ThemeModeStateContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const download = (type) => {
    if(type === "jpeg") {
      downloadImage(domElement, "jpeg", currentTheme.theme)
    }
    if(type === "png") {
      downloadImage(domElement, "png", currentTheme.theme)
    }
    if(type === "svg") {
      downloadImage(domElement, "svg", currentTheme.theme)
    }
    setAnchorEl(null);
  }

  const handleDownloadMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDownloadMenuClose = () => {
    setAnchorEl(null);
  };

  return(
    <>
    <Tooltip title={<TranslationContent contentID={domElement !== "comparison-chart-container" ? "downloadTableAsImage" : "downloadChartAsImage"} />} arrow>
      <span>
        <Button 
          disabled={disabled}
          size="small" 
          onClick={(event) => handleDownloadMenuOpen(event)}
          variant="contained" 
          sx={{marginRight: 1, marginLeft: "auto", width: "70px"}}>
          <DownloadIcon /> <ArrowDropDownIcon />
        </Button>
      </span>
    </Tooltip>
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleDownloadMenuClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <Typography variant="caption" p={1}><b><TranslationContent contentID="downloadAs" /></b></Typography>
      <Divider />
      <MenuItem onClick={() => download("png")}>.png</MenuItem>
      <MenuItem onClick={() => download("jpeg")}>.jpeg</MenuItem>
      <MenuItem onClick={() => download("svg")}>.svg</MenuItem>
    </Menu>
    </>
  )
}

export default DownloadMenu;
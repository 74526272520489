import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { TablePagination } from '@mui/material';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { useViewport } from '../../../Context/ViewportProvider';
import TranslationContent from '../../../Translations/TranslationContent';
import BooleanValue from '../../Utils/GlobalComponents/BooleanValue';


const TableManager = ({ chartData, comparisonData, expandAll }) => {
  if(chartData.filteredData.length < 1) {
    return(<InitialTable chartData={chartData} comparisonData={comparisonData} expandAll={expandAll} />)
  }
  if(chartData.filteredData.length > 0) {
    return(<FilteredTable chartData={chartData} comparisonData={comparisonData} expandAll={expandAll} />)
  }
}

export default TableManager;


function InitialRow({ dataEntry, comparisonData, expandAll }) {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if(expandAll) {
      setOpen(expandAll);
    }
    else {
      setOpen(expandAll)
    }
  },[expandAll])

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} >
        <TableCell align="left">
          <IconButton
            color="primary"
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell >
        
        {comparisonData.headers.map((header, index) => (
          header.uniqueKey.includes(comparisonData.uniqueCategories[0]) ?
          <TableCell 
            align="right" 
            key={header.name+index} 
            sx={{opacity: dataEntry[header.uniqueKey] === "No value" ? 0.4 : "", color: dataEntry[header.uniqueKey] === "Incorrect value type" ? "red" : ""}}
          >
            {header.type === "boolean" ? 
              <BooleanValue value={dataEntry[header.uniqueKey]} />
            :
              <Typography>
                {dataEntry[header.uniqueKey]}
              </Typography>
            }
          </TableCell>
          :null
        ))}
      
      </TableRow>
      
      <TableRow sx={{width: "100%"}}>
        <TableCell  style={{ paddingBottom: 0, paddingTop: 0, width: "100%" }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                <b><TranslationContent contentID="details" /></b>
              </Typography>
              <Grid container>
              {comparisonData.headers.map((header, index) => (
                !header.uniqueKey.includes(comparisonData.uniqueCategories[0]) ?
                <Grid key={header.uniqueKey + dataEntry[header.uniqueKey] + index + "initialRow"} xs={12} md={6} xl={4} sx={{marginBottom: "10px"}}>
                  <Typography fontSize="0.8rem">
                    <b>{header.name}</b>
                  </Typography>
                  {header.type === "boolean" ? 
                    <BooleanValue value={dataEntry[header.uniqueKey]} />
                  :
                    <Typography 
                      sx={{
                        opacity: dataEntry[header.uniqueKey] === "No value" ? 0.4 : "",
                        color: dataEntry[header.uniqueKey] === "Incorrect value type"
                          || dataEntry[header.uniqueKey] === "Value type missing from header" 
                          || dataEntry[header.uniqueKey] === "Date format not valid" ? "red" : ""
                        }}
                      > 
                      {dataEntry[header.uniqueKey]}
                    </Typography>
                  }
                  </Grid>
                :null
              ))}
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}


 function InitialTable({ comparisonData, expandAll }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { width } = useViewport();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  
  return (
    <>
      <TableContainer component={Paper} sx={{maxWidth: width * 0.95}}>
        <Table aria-label="Initial table without filters" size="small" id="table-container">
          <TableHead sx={{borderBottom: "2px solid", background: "rgba(255, 255, 255, 0.12)"}}>
            <TableRow>
              <TableCell align="right" />
              {comparisonData.headers.map((header, index) => (
                header.uniqueKey.includes(comparisonData.uniqueCategories[0]) ?
                <TableCell align="right" key={header.name + index + "initialTableHeaderCell"}><b>{header.name}</b></TableCell>
                :null
                
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {comparisonData.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((dataEntry, index) => (
              <InitialRow key={"datatableEntry" + index + dataEntry[0]} dataEntry={dataEntry} comparisonData={comparisonData} expandAll={expandAll} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={comparisonData.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={<TranslationContent contentID="rowsPerPage" />}
      />
    </>
  );
}

function FilteredRow({ filteredDataEntry, comparisonData, chartData, expandAll }) {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if(expandAll) {
      setOpen(expandAll);
    }
    else {
      setOpen(expandAll);
    }
  },[expandAll])

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            color="primary"
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="right" sx={{opacity: filteredDataEntry[chartData.preferredIdentifier] === "No value" ? 0.4 : "", color: filteredDataEntry[chartData.preferredIdentifier] === "Incorrect value type" ? "red" : ""}}>{filteredDataEntry[chartData.preferredIdentifier]}</TableCell>
        {chartData.stringFilters.map((stringFilter, index) => (
          stringFilter.objectKey !== chartData.preferredIdentifier &&
          <TableCell align="right" key={"FilteredExta" + stringFilter.objectKey+index+"s"} sx={{opacity: filteredDataEntry[stringFilter.objectKey] === "No value" ? 0.4 : "", color: filteredDataEntry[stringFilter.objectKey] === "Incorrect value type" ? "red" : ""}}>{filteredDataEntry[stringFilter.objectKey]}</TableCell>
        ))}
        {chartData.numericFilters.map((numericFilter, index) => (
          <TableCell align="right" key={"FilteredExgra" + numericFilter.objectKey+index+"n"} sx={{opacity: filteredDataEntry[numericFilter.objectKey] === "No value" ? 0.4 : "", color: filteredDataEntry[numericFilter.objectKey] === "Incorrect value type" ? "red" : ""}}>{filteredDataEntry[numericFilter.objectKey]}</TableCell>
        ))}
        {chartData.booleanFilters.map((booleanFilter, index) => (
          <TableCell align="right" key={"FilteredExgra" + booleanFilter.objectKey+index+"n"}><BooleanValue value={filteredDataEntry[booleanFilter.objectKey]} /></TableCell>
        ))}
      
      </TableRow>
      <TableRow sx={{width: "100%"}}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, width: "100%" }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                <b><TranslationContent contentID="details" /></b>
              </Typography>
              <Grid container>
              {comparisonData.headers.map((header, index) => (
                chartData.stringFilters.some(filter => filter.objectKey === header.uniqueKey) || chartData.numericFilters.some(filter => filter.objectKey === header.uniqueKey) ?
                null:
                <Grid key={"filteredRow" + filteredDataEntry[header.uniqueKey] + index} xs={12} md={6} xl={4} sx={{marginBottom: "10px"}}>
                  <Typography fontSize="0.8rem">
                    <b>
                      {header.name}
                    </b>
                  </Typography>
                  {header.type === "boolean" ? 
                    <BooleanValue value={filteredDataEntry[header.uniqueKey]} />
                  :
                    <Typography 
                      sx={{
                        opacity: filteredDataEntry[header.uniqueKey] === "No value" ? 0.4 : "", 
                        color: filteredDataEntry[header.uniqueKey] === "Incorrect value type" 
                          || filteredDataEntry[header.uniqueKey] === "Value type missing from header"
                          || filteredDataEntry[header.uniqueKey] === "Date format not valid" ? "red" : ""
                      }}
                    > 
                      {filteredDataEntry[header.uniqueKey]}
                    </Typography>
                  }
                </Grid>
              ))}
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function FilteredTable({ chartData, comparisonData, expandAll }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  
  return (
    <>
    <TableContainer component={Paper}>
      <Table aria-label="Table for filtered data" size="small" id="table-container">
        <TableHead sx={{borderBottom: "2px solid", background: "rgba(255, 255, 255, 0.12)"}}>
          <TableRow>
            <TableCell />
            <TableCell align="right"><b>{chartData.preferredIdentifier.split(":")[0]}</b></TableCell> 
            {chartData.stringFilters.map((stringFilter, index) => (
              stringFilter.objectKey !== chartData.preferredIdentifier &&
              <TableCell align="right" key={stringFilter.objectKey + index}><b>{stringFilter.objectKey.split(":")[0]}</b></TableCell> 
            ))}
            {chartData.numericFilters.map((numericFilter, index) => (
              <TableCell align="right" key={numericFilter.objectKey + index}><b>{numericFilter.objectKey.split(":")[0]}</b></TableCell>      
            ))}
            {chartData.dateFilters.map((dateFilter, index) => (
              <TableCell align="right" key={dateFilter.objectKey + index}><b>{dateFilter.objectKey.split(":")[0]}</b></TableCell>      
            ))}
            {chartData.booleanFilters.map((booleanFilter, index) => (
              <TableCell align="right" key={booleanFilter.objectKey + index}><b>{booleanFilter.objectKey.split(":")[0]}</b></TableCell>      
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {chartData.filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((filteredDataEntry, index) => (
            <FilteredRow key={"filteredDataTableEntry" + index + Math.random() } filteredDataEntry={filteredDataEntry} comparisonData={comparisonData} chartData={chartData} expandAll={expandAll} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      rowsPerPageOptions={[10, 25, 100]}
      component="div"
      count={chartData.filteredData.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      labelRowsPerPage={<TranslationContent contentID="rowsPerPage" />}
    />
  </>
  );
}
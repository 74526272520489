import React from "react";
import { Paper, Typography } from "@mui/material";
import { ExportContext, ExportDispatchContext } from "../../Context/ExportProvider";
import NoExportContent from "./NoExportContent";
import ButtonToolBar from "./ButtonToolBar";
import PreviewPDF from "../PDF/PreviewPDF";
import ExportContent from "./ExportContent";
import GlobalLayout from "../../Layouts/GlobalLayout";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import TranslationContent from "../../Translations/TranslationContent";


const ExportTool = () => {
  const [activeBottomNavigation, setActiveBottomNavigation] = React.useState('');
  const exportContent = React.useContext(ExportContext);
  const updateExportContent = React.useContext(ExportDispatchContext);
  const [mode, setMode] = React.useState("tool");

  const handleBottomNavigationChange = (event, newValue) => {
    if(newValue === activeBottomNavigation) {
      setMode("tool")
      setActiveBottomNavigation("");
    }
    if(newValue === "add") {
      handleContentAdd();
    }
    if(newValue === "preview" && activeBottomNavigation !== "preview") {
      setActiveBottomNavigation(newValue);
      setMode("preview");
    }
    if(newValue !== activeBottomNavigation && newValue !== "add" && newValue !== "preview") {
      setActiveBottomNavigation(newValue);
    }
  };

  const handleContentAdd = () => {
    let updatedExportContent = [...exportContent];
    let newContent = {
      type: "",
      content: ""
    }
    updateExportContent({type: "UPDATE_EXPORT_LIST", value: [...updatedExportContent, newContent]})
  }



  return(
    <GlobalLayout>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Paper sx={{padding: "7px"}} elevation={5}>
            <Typography color="primary" variant="subtitle1"><b><TranslationContent contentID="export" /></b></Typography>
          </Paper>
        </Grid>
        <Grid xs={12}>
          <Paper elevation={5} sx={{padding: "10px", marginTop: "5px"}} >
            <ButtonToolBar activeBottomNavigation={activeBottomNavigation} handleBottomNavigationChange={handleBottomNavigationChange} position="top" />
            <NoExportContent />
            <ExportContent activeBottomNavigation={activeBottomNavigation} mode={mode} />
            <PreviewPDF showPreview={mode === "preview"} />
            <ButtonToolBar activeBottomNavigation={activeBottomNavigation} handleBottomNavigationChange={handleBottomNavigationChange} position="bottom" />
          </Paper>
        </Grid>
      </Grid>
    </GlobalLayout>
  )
}

export default ExportTool;